<template>
  <!--场地统计 -->
  <div class="equipmentStatis">
    <div class="datarow">
      <div class="block">
        <span class="demonstration">日期</span>
        <el-date-picker
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <div>
        <span class="demonstration">场地</span>
        <el-select v-model="addressvalue" filterable placeholder="请选择">
          <el-option
            v-for="item in device_list"
            :key="item.id"
            :label="item.address_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div style="text-align: right; margin-bottom: 10px">
      <el-button type="success" size="mini" @click="search()">查询</el-button>
      <el-button type="primary" size="mini" @click="reset()">重置</el-button>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%; max-height: 65vh; overflow-y: scroll"
    >
      <el-table-column prop="address_name" label="场地名称"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column label="是否开启">
        <template #default="scope">
          <span>{{ scope.row.is_use == 1 ? "已开启" : "未开启" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="添加时间"></el-table-column>
      <el-table-column prop="device_count" label="设备统计"></el-table-column>
      <el-table-column prop="" label="套餐数量">
        <template #default="scope">
          <div>{{scope.row.package_count}}</div>&nbsp;
          <el-button
            type="primary"
            @click="$router.push('/taocandimens?datevalue='+ datevalue + '&addressid=' + addressvalue  )"
            v-if="search"
            >详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="order_cash_sum" label="成交金额"></el-table-column>
      <el-table-column
        prop="month_charge"
        label="月均充电次数"
      ></el-table-column>
      <el-table-column
        prop="total_charge"
        label="总充电次数"
      ></el-table-column>
      <el-table-column prop="user_count" label="用户总数"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { addressCount } from "../../request/api";
import Moment from "moment";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      datevalue: ["", ""], //日期
      device_list: [],
      addressvalue: "", //场地
      tableData: [],
      pageTotal: 0,
    };
  },
  methods: {
    list() {
      return new Promise((resolve, reject) => {
        addressCount({
          page: this.page,
          page_size: this.pagepage,
           start_time: this.datevalue[0]
          ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss")
          : "",
        end_time: this.datevalue[1]
          ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss")
          : "",
          address_id: this.addressvalue,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.list;
          this.tableData.forEach((item) => {
            item.month_charge = Number(item.month_charge);
            item.month_charge = item.month_charge.toFixed(2);
          });
          this.device_list = res.list.address_list;
        });
      });
    },

    async reset() {
      this.addressvalue = "";
      this.datevalue = "";
      await this.list();
    },
    async search() {
      await this.list();
    },
  },
  async created() {
    this.$emit("isshowpageclick");
    this.pageTotal = await this.list();
  },
  watch: {
    page() {
      this.list();
    },
    pagepage() {
      this.list();
    },
  },
};
</script >
<style lang='less' scoped>
.datarow {
  display: flex;
  justify-content: space-between;
}
/deep/.has-gutter th {
  background-color: #f5f7fa;
}

/deep/.cell {
  text-align: center;
}
.yesonmon {
  color: #3f7ee2;
  line-height: 80px;
  text-align: center;
}
.datarow {
  padding: 20px 0;
  .demonstration {
    margin-right: 10px;
  }
}
</style>
